<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-base">
    <div class="flex flex-col">
      <div class="text-2xl font-bold">{{ $t("general.places") }}</div>
      <template
        v-for="item in regionPlaces"
        :key="`${id}-region-place-${item.id}`"
      >
        <NuxtLink
          v-if="placeVacanciesCount[item.id] > 0"
          class="group cursor-pointer flex gap-1 items-center"
          :to="localePath(`/vacatures-in/${item.attributes.slug}`)"
        >
          <span class="no-underline group-hover:underline">{{
            item.attributes.title
          }}</span>
          <div
            v-if="placeVacanciesCount[item.id] > 0"
            class="text-xs opacity-60"
          >
            ({{ placeVacanciesCount[item.id] }})
          </div>
        </NuxtLink>
      </template>
    </div>
    <div class="flex flex-col lg:col-span-2">
      <div class="text-2xl font-bold">{{ $t("general.professions") }}</div>
      <div class="lg:columns-2 w-full">
        <template
          v-for="item in professions"
          :key="`${id}-profession-${item.id}`"
        >
          <NuxtLink
            class="block cursor-pointer no-underline hover:underline"
            :to="
              localePath(`/vacatures-in-beroepsgroep/${item.attributes.slug}`)
            "
            >{{ item.attributes.title }}
          </NuxtLink>
        </template>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">{{ $t("general.employments") }}</div>
      <NuxtLink
        v-for="item in employments"
        :key="`${id}-employment-${item.id}`"
        class="block cursor-pointer no-underline hover:underline"
        :to="localePath(`/vacatures`) + `?professions=${item.id}`"
        >{{ item.attributes.title }}</NuxtLink
      >
    </div>
    <div class="flex flex-col">
      <div class="text-2xl font-bold">{{ $t("general.educations") }}</div>
      <NuxtLink
        v-for="item in educations"
        :key="`${id}-education-${item.id}`"
        class="block cursor-pointer no-underline hover:underline"
        :to="localePath(`/vacatures`) + `?educations=${item.id}`"
        >{{ item.attributes.title }}</NuxtLink
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  StrapiBlock,
  StrapiAttributes,
} from "@flyingkiwi/nuxt-components";
const localePath = useLocalePath();
const { regionPlaces, professions, employments, educations } = useStaticData();
const { find } = useStrapi();

const placeVacanciesCount = ref<{ [key: number]: number }>({});
const professionVacanciesCount = ref<{ [key: number]: number }>({});

type VacanciesResponse = {
  places: {
    data: { id: number }[];
  };
  professions: {
    data: { id: number }[];
  };
} & StrapiAttributes;
const { data: vacanciesData } = await useAsyncData(
  "search-vacancies-links-vacacies",
  () =>
    find<VacanciesResponse>("vacancies", {
      pagination: {
        page: 1,
        pageSize: 9999,
      },
      filters: {
        status: "open",
        // professions: professionId,
      },
      fields: ["title"],
      populate: {
        places: {
          fields: ["id"],
        },
        professions: {
          fields: ["id"],
        },
      },
    }),
);

const getPlaceVacancies = async (placeId: number) => {
  const count =
    vacanciesData.value?.data.filter((vacancy) =>
      vacancy.attributes?.places?.data?.some((place) => place.id === placeId),
    ).length ?? 0;
  return count;
};

// Function to fetch vacancies count for a list of place IDs
const getPlacesVacancies = async (regionPlacesIds: number[]) => {
  const counts = await Promise.all(
    regionPlacesIds.map(async (placeId: number) => {
      const count = await getPlaceVacancies(placeId);
      return { placeId, count };
    }),
  );
  counts.forEach(({ placeId, count }) => {
    placeVacanciesCount.value[placeId] = count;
  });
};

const getProfessionVacancies = async (professionId: number) => {
  const count =
    vacanciesData.value?.data.filter((vacancy) =>
      vacancy.attributes?.professions?.data?.some(
        (prof) => prof.id === professionId,
      ),
    ).length ?? 0;
  return count;
};
// Function to fetch vacancies count for a list of place IDs
const getProfessionsVacancies = async (professionIds: number[]) => {
  const counts = await Promise.all(
    professionIds.map(async (professionId: number) => {
      const count = await getProfessionVacancies(professionId);
      return { professionId, count };
    }),
  );
  counts.forEach(({ professionId, count }) => {
    professionVacanciesCount.value[professionId] = count;
  });
};

onMounted(() => {
  const regionPlacesIds: number[] = regionPlaces.value.map((place) => place.id);
  if (
    regionPlacesIds &&
    Array.isArray(regionPlacesIds) &&
    regionPlacesIds.length > 0
  ) {
    getPlacesVacancies(regionPlacesIds);
  }
  const professionIds: number[] = professions.value.map(
    (profession) => profession.id,
  );
  if (
    professionIds &&
    Array.isArray(professionIds) &&
    professionIds.length > 0
  ) {
    getProfessionsVacancies(professionIds);
  }
});

defineProps<StrapiBlock>();
</script>
